import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { media } from '@dfds-ui/theme'
import { SystemIcon } from '@dfds-ui/icons'
import { Headline } from '@dfds-ui/react-components'

import { FlexCard } from '../components/Card'
import { FlexBox } from '../components/FlexBox'
import { MediaLibraryEntry } from '../components/MediaLibraryEntry'
import Hero from '../components/Hero/Hero'
import { PageLayout } from '../components/Layout'
import { Faq } from '../components/FAQ'

const CleanText = (text) => {
  return text.replace(/[^A-Z0-9]/gi, '')
}
const ScrollToItem = ({ title }) => {
  return (
    <AnchorLink
      href={`#entryId_${CleanText(title)}`}
      onClick={(e) => {
        e.preventDefault()
        document
          .querySelector(`#entryId_${CleanText(title)}`)
          .scrollIntoView({ behavior: 'smooth' })
      }}
    >
      <SystemIcon icon="ArrowDown" />
      {title}
    </AnchorLink>
  )
}

const AnchorLink = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 20px 10px 0;
  display: flex;
  align-items: center;

  ${(props) => props.alignRight && 'justify-content: end;'}
  ${(props) =>
    props.alignRight && media.greaterThan('l') && 'padding: 10px 0 10px 0;'}
`

const LinksList = styled(FlexBox)`
  margin: 0 20px;

  ${media.greaterThan('l')`
    margin: 0 60px 0 0;
  `}
`

const SimpleAnchorLinkList = (props) => {
  const { items } = props

  const links = items.map((item, i) => {
    return <ScrollToItem key={i} title={item} />
  })

  return (
    <FlexCard
      noPadding={true}
      css={css`
        margin-bottom: 20px;
      `}
      background
    >
      <LinksList wrapWrap justifyStart>
        {links}
      </LinksList>
    </FlexCard>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  margin-bottom: 40px;

  ${media.greaterThan('l')`
    flex-direction: row;
  `}

  > div {
    width: 100%;
    margin-bottom: 40px;

    ${media.greaterThan('l')`
      &[md="8"], &[lg="8"] {
        width: calc(66.66% - 20px);
      }
      &[md="6"], &[lg="6"] {
        width: calc(50% - 20px);
      }
      &[md="4"], &[lg="4"] {
        width: calc(33.33% - 26.66px);
      }
      &[md="3"], &[lg="3"] {
        width: calc(25% - 30px);
      }
    `}
  }
`

const LibraryPageContent = ({ title, references, layout, showToTop }) => {
  const layoutMap = {
    1: 'full-width',
    2: 'one-half',
    3: 'one-third',
    4: 'one-fourth',
  }
  if (!title && references.items.length === 0) return null
  return (
    <PageLayout>
      {title && (
        <FlexBox
          justifySpaceBetween
          css={css`
            align-items: center;
          `}
        >
          <Headline
            id={`entryId_${CleanText(title)}`}
            as={'h2'}
            css={css`
              margin-left: 20px;
              margin-right: 20px;

              ${media.greaterThan('m')`
                margin-left: 0;
                margin-right: 0;
              `}
            `}
          >
            {title}
          </Headline>
          {showToTop && (
            <AnchorLink
              href="#"
              onClick={(e) => {
                e.preventDefault()
                document
                  .querySelector('body')
                  .scrollIntoView({ behavior: 'smooth' })
              }}
              alignRight={true}
              css={css`
                margin-right: 20px;

                ${media.greaterThan('l')`
                  margin-right: 0;
                `}
              `}
            >
              <SystemIcon icon="ArrowUp" />
              back to top
            </AnchorLink>
          )}
        </FlexBox>
      )}
      <Wrapper>
        {references &&
          references.items.map((item, index) => (
            <MediaLibraryEntry
              spaced
              key={`cat1${index}`}
              {...item}
              width={layoutMap[layout]}
            />
          ))}
      </Wrapper>
    </PageLayout>
  )
}
const LibraryPage = ({ data: { content } }) => {
  const {
    hero,
    category1Title,
    category1NumberOfItemsPerRow,
    category1ReferencesCollection,
    category2Title,
    category2NumberOfItemsPerRow,
    category2ReferencesCollection,
    category3Title,
    category3NumberOfItemsPerRow,
    category3ReferencesCollection,
    category4Title,
    category4NumberOfItemsPerRow,
    category4ReferencesCollection,
    category5Title,
    category5NumberOfItemsPerRow,
    category5ReferencesCollection,
    category6Title,
    category6NumberOfItemsPerRow,
    category6ReferencesCollection,
    faqSection,
  } = content.page
  const rawheadings = [
    category1Title,
    category2Title,
    category3Title,
    category4Title,
    category5Title,
    category6Title,
  ]
  const headings = rawheadings.filter((n) => n)

  return (
    <>
      <PageLayout>
        {hero?.contentRef && <Hero {...hero.contentRef} />}
        <SimpleAnchorLinkList items={headings} />
      </PageLayout>
      <LibraryPageContent
        title={category1Title}
        references={category1ReferencesCollection}
        layout={category1NumberOfItemsPerRow}
      />
      <LibraryPageContent
        title={category2Title}
        references={category2ReferencesCollection}
        layout={category2NumberOfItemsPerRow}
        showToTop
      />
      <LibraryPageContent
        title={category3Title}
        references={category3ReferencesCollection}
        layout={category3NumberOfItemsPerRow}
        showToTop
      />
      <LibraryPageContent
        title={category4Title}
        references={category4ReferencesCollection}
        layout={category4NumberOfItemsPerRow}
        showToTop
      />
      <LibraryPageContent
        title={category5Title}
        references={category5ReferencesCollection}
        layout={category5NumberOfItemsPerRow}
        showToTop
      />
      <LibraryPageContent
        title={category6Title}
        references={category6ReferencesCollection}
        layout={category6NumberOfItemsPerRow}
        showToTop
      />
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <PageLayout>
            <Faq
              {...faqSection.itemsCollection}
              heading={faqSection?.heading}
              urlSlug={faqSection?.urlSlug?.slug}
              defaultExpanded={faqSection?.defaultExpanded ?? false}
            />
          </PageLayout>
        )}
    </>
  )
}

export const query = graphql`
  query LibraryPage($id: String!, $locale: String!, $preview: Boolean = false) {
    content: contentful {
      page: libraryPage(id: $id, locale: $locale, preview: $preview) {
        hero: heroReference(locale: $locale) {
          ... on contentful_PaxHeroLayout {
            __typename
            sys {
              id
            }
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        category1Title
        category1NumberOfItemsPerRow
        category1ReferencesCollection(limit: 40) {
          items {
            ...MediaLibraryEntry
          }
        }
        category2Title
        category2NumberOfItemsPerRow
        category2ReferencesCollection(limit: 60) {
          items {
            ...MediaLibraryEntry
          }
        }
        category3Title
        category3NumberOfItemsPerRow
        category3ReferencesCollection(limit: 40) {
          items {
            ...MediaLibraryEntry
          }
        }
        category4Title
        category4NumberOfItemsPerRow
        category4ReferencesCollection(limit: 40) {
          items {
            ...MediaLibraryEntry
          }
        }
        category5Title
        category5NumberOfItemsPerRow
        category5ReferencesCollection(limit: 40) {
          items {
            ...MediaLibraryEntry
          }
        }
        category6Title
        category6NumberOfItemsPerRow
        category6ReferencesCollection(limit: 40) {
          items {
            ...MediaLibraryEntry
          }
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default LibraryPage
